import '@zonejs-patch';

import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Bundle, BundleEntry, Goal, PlanDefinition } from 'fhir/r4';
import { CreateProgramComponent } from './components/create-program/create-program.component';
import { DefaultDialogProperties, DialogResponsiveWidth, ORG_PERSIST_KEY, ProgramStatus, spinnerProperties } from './config/app.config';
import { GoalService } from './services/goal.service';
import { ProgramService } from './services/program.service';
import { NotificationService } from './services/notification.service';
import { ConfirmDialogData, CreateProgramDialogData } from './models/matDialogData.model';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { catchError, filter, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { EventMessage, InteractionStatus, AuthenticationResult, RedirectRequest, EventType, SilentRequest, AuthError, InteractionRequiredAuthError } from '@azure/msal-browser';
// todo: Uncomment below line when you add extra routes, to listen to side app router events
// import { activeRoute$ } from '@bayshoreHealthCare/store';
import { showSidebar$ } from '@bayshoreHealthCare/store';
import { BreakpointService } from './services/breakpoint.service';
import { CustomBreakpointState } from './shared/interfaces/custom_breakpoint_state_interface';
import { MapBundleToResourceArray } from './mappers/shared.mapper';
import { ShowLoaderService } from './services/show-loader.service';
import { InactivityService } from './services/inactivity.service';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CalcomService } from './services/calcom.service';

@Component({
  selector: 'program-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  goals!: Array<Goal>;
  userProfile: any;
  isIframe = false;
  loginDisplay = false;
  isCreateProgramButtonDisabled = true;
  isExistingProgramNamesUpdated = false;
  existingProgramNames!: string[];
  allPrograms!: PlanDefinition[];
  showSpinnerInChild = true;
  defaultSpinnerProperties = spinnerProperties;
  isShowLoadingSectionVisible = true;

  // Used to control access for clinician users
  viewOnly = true;

  @HostBinding('class.tablet') isTablet: boolean = false;
  @HostBinding('class.sidebar-visible') isSidebarVisible = false;
  @HostBinding('class.large') large: boolean = false;
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    public dialog: MatDialog,
    private programService: ProgramService,
    private goalService: GoalService,
    private notificationService: NotificationService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private breakpointService: BreakpointService,
    private showLoaderService: ShowLoaderService,
    private inactivityService: InactivityService,
    private calcomService: CalcomService,
  ) { }

  ngOnInit(): void {
    // get all program names so that new program names can be validated for dupliates
    this.retrievePrograms();
    this.programService.refreshProgramTable
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => this.retrievePrograms());

    this.showLoaderService.isShowLoadingSectionVisible$
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((isLoading: boolean) => this.isShowLoadingSectionVisible = isLoading);

    /**
    * Navigate to this app's root path (programs list) whenever
    * we trigger route from sidebar app
    */
    //  todo: Uncomment below subscription if using angular router (when this app has other screens)
    // activeRoute$
    //   .pipe(
    //     takeUntil(this.unsubscribe),
    //     catchError((err: any) => {
    //       console.log('Users App: RPM Store Active route error : ', err)
    //       return of(false);
    //     })
    //   )
    //   .subscribe((url: any) => {
    //     if (String(url).toLowerCase()==="programs") {
    //      todo: change the below url to point to the root path or home path of this app
    //       this.router.navigate(['/']);
    //     }
    //   })

    /**
   * Listen for breakpoint changes
   */
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.large = value.Large;
        this.xlarge = value.XLarge;
        this.isTablet = value.Tablet;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });


    /**
     * Listen sidebar toggle status
     */
    showSidebar$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((sidebarVisible: boolean) => {
        this.isSidebarVisible = sidebarVisible;
      })



    // this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    if (this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      const account = accounts[0];
      this.authService.instance.setActiveAccount(accounts[0]);
      this.userProfile = account.idTokenClaims;
      this.authService.acquireTokenSilent({
        account: account,
      } as SilentRequest).subscribe();
    }
    // #R2-1190 - Handled clinician user access control
    const activeOrganization: string[] = JSON.parse(localStorage.getItem(ORG_PERSIST_KEY) || '[]');
    this.viewOnly = !activeOrganization.length || (activeOrganization.length === 3 && activeOrganization[2].toLowerCase().includes('clinician'));

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          console.log(
            `Program App:  No account found:${this.authService.instance.getAllAccounts().length
            }`
          );
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    // when session expires, the data and api will fail, and
    // need the user to relogin. 
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((result: EventMessage) => {
        const error = result.error as AuthError;
        if (error instanceof InteractionRequiredAuthError) {
          this.authService.loginRedirect();
        }
      });

    // To offset msal's delay in writing credentials to localStorage
    setTimeout(() => {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (!activeAccount) {
        window.location.replace('/auth');
      }
    }, 2000);

    this.getGoals()
    this.inactivityService.startInactivityTimer();
  }

  /* 
    * #E2-209 - get goals used to call twice
  */
  getGoals() {
    this.goalService.getGoals()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((goals: Bundle<Goal>) => {
        this.goals = goals?.entry?.length
          ? goals.entry.map(({ resource }: BundleEntry<Goal>) => resource!)
          : [];
        this.isCreateProgramButtonDisabled = false;
      });
  }

  /**
   * Retrieves all programs and pass it to program listing component
   */
  retrievePrograms(): void { 
    this.showSpinnerInChild = true;
    this.isExistingProgramNamesUpdated = false;
    this.programService.getPrograms()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (bundle: Bundle<PlanDefinition>) => {
          const programs = MapBundleToResourceArray<PlanDefinition>(bundle);
          this.allPrograms = programs;
          this.existingProgramNames = programs.map((program: PlanDefinition) => program?.title ? program.title.toLowerCase() : '');
          this.isExistingProgramNamesUpdated = true;
          this.showSpinnerInChild = false;
          this.showLoaderService.isShowLoadingSectionVisible$.next(false);
        }
      });
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  openPatientInformationDialog() {
    // #R2-1190 - Handled clinician user access control
    if (this.viewOnly) {
      this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        ...DefaultDialogProperties,
        data: {
          confirmationHeader: 'Access Denied',
          confirmationQuestion: 'You don\'t have permissions to perform this action, please contact your administrator.',
          okButton: true
        }
      })
      .afterClosed()
      return
    }

    // #R2-523 - bug fix - Modified threshold values needs to be reset
    this.getGoals();
    let dialogWidth = DialogResponsiveWidth.large;

    if (this.isTablet) dialogWidth = DialogResponsiveWidth.tablet;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;

    this.dialog.open<CreateProgramComponent, CreateProgramDialogData>(
      CreateProgramComponent, {
      ...DefaultDialogProperties,
      width: dialogWidth,
      data: {
        goals: this.goals,
        existingProgramNames: this.existingProgramNames
      }
    })
    .afterClosed()
    .pipe(
      takeUntil(this.unsubscribe$)  
    )  
    .subscribe((createProgram: any) => {
      if (!createProgram) {
        return;
      }
      
      // R2-1553: create calcom team only when publishing programs
      const createProgram$ = (programData: any) => {
        return this.programService.createProgram(programData)
          .pipe(
            catchError((error) => {
              this.notificationService.showNotification("Error occurred when creating program. Try again later", "error", "Ok");
              return throwError(() => new Error(error?.message || 'Error occurred when creating program'));
            })
          );
      };

      const createTeamAndProgram$ = (programData: any) => {
        return this.calcomService.createTeam(programData.formValue.programName)
          .pipe(
            catchError((error) => {
              this.notificationService.showNotification("Error occurred when creating Calcom Team. Try again later", "error", "Ok");
              return throwError(() => new Error(error?.message || 'Error occurred when creating Calcom Team'));
            }),
            switchMap((teamResponse: any) => {
              const calcomTeamId = teamResponse?.team?.id?.toString();
              if (!calcomTeamId) {
                return throwError(() => new Error('Calcom teamId not available'));
              }
              return createProgram$({ ...programData, calcomTeamId });
            })
          );
      };

      const saveDraftOrPublishProgram$ = createProgram.status === ProgramStatus.ACTIVE
        ? createTeamAndProgram$(createProgram)
        : createProgram$(createProgram);

      saveDraftOrPublishProgram$
        .pipe(
          takeUntil(this.unsubscribe$)
        )
        .subscribe({
          next: () => {
            // #R2-209 - default goals and threshold values can be reset after success 
            this.getGoals();
            this.programService.refreshProgramTable.next(null);
            this.notificationService.showNotification("Program Saved Successfully", "success", "Ok");
          },
        });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  /**
 * Function used to delete all Programs. 
 * This function is only used for deleting programs for demo, 
 * TODO: Delete this Function !
 * @returns 
 */
  deletePrograms() {
    this.programService.getPrograms()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((programs: any) => {
        const data = programs?.entry?.length
          ? programs.entry
            .map(({ resource }: BundleEntry<PlanDefinition>) => resource!)
          : [];
        data.map(async (item: any) => {
          if (item?.id) {
            this.programService.deleteProgram(item?.id).subscribe()
          }
        })
        console.log("Finished deleting !")
      });
  }

  /**
 * Function used to delete all goals except ones with status on-hold. 
 * This function is only used for deleting goals for demo, 
 * TODO: Delete this Function !
 * @returns 
 */
  deleteGoals() {
    this.goalService.deleteGoals()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        console.log("Finished Deleting Goals : ");
      });
  }

  deletePatients() {
    this.goalService.deletePatients()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        console.log("Finished Deleting Patients : ");
      });
  }

  deleteTasks() {
    this.goalService.deleteTasks()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        console.log("Finished Deleting Tasks : ");
      });
  }

  deleteCarePlans() {
    this.goalService.deleteCarePlans()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        console.log("Finished Deleting CarePlans : ");
      });
  }
}
