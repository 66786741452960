import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base-api.service';
import { Bundle, ContactDetail, PlanDefinition } from 'fhir/r4';
import { MapUpdatedGoals } from '../mappers/goal.mapper';
import { DefaultResourceQueryCount, ProgramStatus } from '../config/app.config';
import { ActionForProgram } from '../models/calcom.model';
import { MapActionToProgram } from '../mappers/shared.mapper';

@Injectable({
  providedIn: 'root'
})
export class ProgramService extends BaseService {
  refreshProgramTable = new Subject<null>();

  constructor(
    private http: HttpClient,
  ) { 
    super();
  }
  
  getPrograms(): Observable<Bundle<PlanDefinition>> {
    return this.http.get<Bundle<PlanDefinition>>(`${this.FHIR_BASE}/PlanDefinition?_count=${DefaultResourceQueryCount}&_sort=-_lastUpdated`);
  }

  createProgram(
    { formValue, presetMetrics, status, calcomTeamId = '' }: { formValue: any, presetMetrics: any, status: any, calcomTeamId: string }
  ): Observable<PlanDefinition> {
    const action: ActionForProgram[] = MapActionToProgram(calcomTeamId);
    const goal = MapUpdatedGoals(formValue, presetMetrics);
    const contact = this.getContactPayload(formValue.email);
    const program: PlanDefinition = {
      resourceType: "PlanDefinition",
      title: formValue.programName,
      description: formValue.programDescription,
      status,
      goal,
      contact,
      // only published programs should have action[] with valid calcom team id
      ...(status === ProgramStatus.ACTIVE && { action }),
    };
    return this.http.post<PlanDefinition>(`${this.FHIR_BASE}/PlanDefinition`, program);
  }

  updateProgram(
    { formValue, presetMetrics, status }: { formValue: any, presetMetrics:any, status: any },
    programId: string,
    calcomTeamId = '',
  ): Observable<PlanDefinition> {
    const action = MapActionToProgram(calcomTeamId);
    const goal = MapUpdatedGoals(formValue, presetMetrics);
    const contact = this.getContactPayload(formValue.email);
    const program: PlanDefinition = {
      resourceType: "PlanDefinition",
      title: formValue.programName,
      description: formValue.programDescription,
      id: programId,
      status,
      goal,
      contact,
      // only published programs should have action[] with valid calcom team id
      ...(status === ProgramStatus.ACTIVE && { action }),
    };
    return this.http.put<PlanDefinition>(`${this.FHIR_BASE}/PlanDefinition/${programId}`, program);
  }

  deleteProgram(programId: string): Observable<any> {
    return this.http.delete(`${this.FHIR_BASE}/PlanDefinition/${programId}`, { observe: 'response' });
  }

  private getContactPayload(emails: string[]): ContactDetail[] {
    return [
      {
        name: 'Notification Contact',
        telecom: emails.map((value: string) => ({
          system: 'email',
          use: 'work',
          value
        }))
      }
    ];
  }
}
