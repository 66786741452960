import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostBinding } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ProgramService } from 'src/app/services/program.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { CreateProgramComponent } from '../create-program/create-program.component';
import { PlanDefinition } from 'fhir/r4';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { NotificationService } from 'src/app/services/notification.service';
import { DefaultDialogProperties, DialogResponsiveWidth, MatTableDefaultPageSizes, ProgramStatus } from 'src/app/config/app.config';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData, CreateProgramDialogData } from 'src/app/models/matDialogData.model';
import { spinnerProperties } from 'src/app/config/app.config';
import { catchError, EMPTY, Observable, of, Subject, switchMap, takeUntil, throwError } from 'rxjs';
import { BreakpointService } from 'src/app/services/breakpoint.service';
import { CustomBreakpointState } from 'src/app/shared/interfaces/custom_breakpoint_state_interface';
import { ShowLoaderService } from 'src/app/services/show-loader.service';
import { CalcomService } from 'src/app/services/calcom.service';

@Component({
  selector: 'program-app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', visibility: 'hidden' })
      ),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProgramListComponent implements OnInit {
  displayedColumns = ['title', 'description', 'status', 'action'];
  displayedColumnsWithExpand = [...this.displayedColumns, 'expand'];
  expandedElement: any;
  dataSource = new MatTableDataSource<any>();
  paginationSizes: number[] = MatTableDefaultPageSizes;
  defaultPageSize = this.paginationSizes[0];
  spinnerProps = spinnerProperties;
  unsubscribe$: Subject<void> = new Subject();

  @ViewChild(MatPaginator, { static: false }) matPaginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  @Input() showSpinner!: boolean;
  @Input() goals: any[] = [];
  @Input() existingProgramNames!: string[];
  @Input() set allPrograms(value: PlanDefinition[]) { 
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
  // To handle actions(create, edit & delete)
  @Input() viewOnly: boolean = true;

  // reset goal event used to call to fetch default threshold values
  @Output() resetGoal = new EventEmitter<any>();


  /** Used for setting as xlarge screen for responsiveness */
  @HostBinding('class.xlarge') xlarge: boolean = false;
  @HostBinding('class.retina') retina: boolean = false;
  @HostBinding('class.retina-xdr') retinaXdr: boolean = false;
  @HostBinding('class.retina-4k') retina4k: boolean = false;

  constructor(
    private programService: ProgramService,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private breakpointService: BreakpointService,
    private showLoaderService: ShowLoaderService,
    private calcomService: CalcomService,
  ) { }

  ngOnInit() {
    this.breakpointService.breakpoint$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value: CustomBreakpointState) => {
        this.xlarge = value.XLarge;
        this.retina = value.Retina;
        this.retinaXdr = value.RetinaXDR;
        this.retina4k = value.Retina4k;
      });
  }

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  // expandedElement: any;

  onEditProgram(event: Event, rowItem: PlanDefinition) {
    event.stopPropagation();
    // #R2-1190 - Handled clinician user access control
    if (this.viewOnly) {
      this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        ...DefaultDialogProperties,
        data: {
          confirmationHeader: 'Access Denied',
          confirmationQuestion: 'You don\'t have permissions to perform this action, please contact your administrator.',
          okButton: true
        }
      })
      .afterClosed()
      return
    }
    let dialogWidth = DialogResponsiveWidth.large;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;

    // #R2-523 - bug fix - Modified threshold values needs to be reset
    this.resetGoal.emit();

    this.dialog.open<CreateProgramComponent, CreateProgramDialogData>(
      CreateProgramComponent, {
      ...DefaultDialogProperties,
      width: dialogWidth,
      data: {
        goals: this.goals,
        editProgram: true,
        rowData: rowItem,
        existingProgramNames: this.existingProgramNames,
      }
    })
    .afterClosed()
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((editedProgram: any) => {
      if (!editedProgram) {
        return;
      }
      
      // update program PUT API
      const { id: programId } = rowItem;
      if (!programId) {
        throw new Error('PlanDefinition id not available');
      }
      this.showLoaderService.isShowLoadingSectionVisible$.next(true);

      // R2-1553: only published programs will have action[]
      const editedProgramHasActionsArray = !!rowItem?.action;
      if (!editedProgramHasActionsArray && editedProgram.status === ProgramStatus.DRAFT) {
        // save program in draft state
        this.callUpdateProgramAPI(editedProgram, programId);
        return;
      }
      // for publishing program, save edited program with calcom team id
      const calcomTeamId = rowItem?.action?.[0]?.code?.[0]?.coding?.[0]?.code || '';
      if (!calcomTeamId) {
        this.calcomService.createTeam(editedProgram.formValue.programName)
          .pipe(
            catchError((error) => {
              this.notificationService.showNotification("Error occurred when creating Calcom Team. Try again later", "error", "Ok");
              return throwError(() => new Error(error?.message || 'Error occurred when creating Calcom Team'));
            }),
            switchMap((teamResponse: any) => {
              const calcomTeamId = teamResponse?.team?.id?.toString();
              if (!calcomTeamId) {
                return throwError(() => new Error('Calcom teamId not available'));
              }
              this.callUpdateProgramAPI(editedProgram, programId, calcomTeamId);
              return of(null);
            }),
            takeUntil(this.unsubscribe$),
          )
          .subscribe();
        return;
      }
      this.callUpdateProgramAPI(editedProgram, programId, calcomTeamId);
    });
  }

  private callUpdateProgramAPI(editedProgram: any, programId: string, calcomTeamId = '') {
    return this.programService.updateProgram(editedProgram, programId, calcomTeamId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe({
      next: () => {
        this.programService.refreshProgramTable.next(null);
        this.notificationService.showNotification("Program Saved Successfully", "success", "Ok");
        // no need to reset the loader since latest programs need to be fetched and updated to allPrograms property
      },
      error: () => {
        this.notificationService.showNotification("Error occured when creating program. Try again later", "error", "Ok");
        this.showLoaderService.isShowLoadingSectionVisible$.next(false);
      }
    });
  }

  onDeleteProgram(event: Event, rowItem: PlanDefinition): void {
    event.stopPropagation();
    // #R2-1190 - Handled clinician user access control
    if (this.viewOnly) {
      this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
        ...DefaultDialogProperties,
        data: {
          confirmationHeader: 'Access Denied',
          confirmationQuestion: 'You don\'t have permissions to perform this action, please contact your administrator.',
          okButton: true
        }
      })
      .afterClosed();
      return
    }
    this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(
      ConfirmDialogComponent, {
      ...DefaultDialogProperties,
      data: {
        confirmationHeader: 'Delete Program',
        confirmationQuestion: 'Do you want to delete this program?'
      }
    })
    .afterClosed()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((confirmation: boolean) => {
      if (confirmation) {
        const { id: programId } = rowItem;
        if (!programId) {
          throw new Error('PlanDefinition id not available');
        }
        this.showLoaderService.isShowLoadingSectionVisible$.next(true);
        this.programService.deleteProgram(programId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: (response: any) => {
              if (response.status === 204) {
                this.programService.refreshProgramTable.next(null);
                this.notificationService.showNotification("Program Deleted Successfully", "success", "Ok");
                // no need to reset the loader since latest programs need to be fetched and updated to allPrograms property
              } else {
                this.notificationService.showNotification("Error occured when deleting program. Try again later", "error", "Ok");
                this.showLoaderService.isShowLoadingSectionVisible$.next(false);
              }
            },
            error: () => {
              this.notificationService.showNotification("Error occured when deleting program. Try again later", "error", "Ok");
              this.showLoaderService.isShowLoadingSectionVisible$.next(false);
            }
          });
      }
    });
  }

  onViewProgram(event: Event, rowItem: PlanDefinition): void {
    event.stopPropagation();

    let dialogWidth = DialogResponsiveWidth.large;
    if (this.xlarge) dialogWidth = DialogResponsiveWidth.xlarge;
    if (this.retina) dialogWidth = DialogResponsiveWidth.retina;
    if (this.retinaXdr) dialogWidth = DialogResponsiveWidth.retinaXdr;
    if (this.retina4k) dialogWidth = DialogResponsiveWidth.retina4k;

    this.dialog.open<CreateProgramComponent, CreateProgramDialogData>(
      CreateProgramComponent, {
      ...DefaultDialogProperties,
      width: dialogWidth,
      data: {
        viewProgram: true,
        rowData: rowItem,
        goals: [],
      }
    })
    .afterClosed();
  }

  /**
   * #R2-584 Firefox main element scroll height issue fix
   */
  onChangeItemPerPage(){
    if (navigator.userAgent.indexOf("Firefox") === -1) return;
    setTimeout(() => {
      const mainElement = document.querySelector("main");
      if (mainElement) {
        mainElement.scrollTop = (document.querySelector("main > div:last-child") as HTMLDivElement).scrollHeight - 900;
      }
    }, 100)
  }
}